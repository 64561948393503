import React from "react";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import styles from "./Getfunded.module.scss";

export const formatOptionLabel = ({ label, imageUrl }) => (
  <div className={styles.selectOption}>
    <div className={`d-flex align-items-center`}>
      <div className={styles.profile}>
        {imageUrl ? (
          <img src={imageUrl} alt="artist_profile" />
        ) : (
            <SvgIcons icon={PROFILE_ICON} />
          )}
      </div>
      <div>
        <p>{label}</p>
      </div>
    </div>
    <button type="button" className="btn">
      Select
    </button>
  </div>
);



