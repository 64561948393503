import React, { Component } from "react";
import "./index.scss";
import Recaptcha from "react-google-recaptcha";
import invoke from "lodash.invoke";
import Select from "react-select/async";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "../../../../component/FormField/FormField";
import styles from "./Getfunded.module.scss";
import authStyles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  SIGNUP_API,
  ACK_REDIRECT,
  HEADER_TEXT,
  ADMIN,
  WHITELABEL_INSTANCE,
  LOGIN,
  VALIDATE_PARTNER,
} from "../../constants";
import Loader from "../../../../component/Loader";
import { EMAIL_REGEX } from "../constants";
import request from "../../../../utils/request";
import autofillStyle from "./autofillStyle";
import { formatOptionLabel } from "./formatOptionLabel";
import {
  NoOptionsMessage,
  LoadingMessage,
  SingleValue,
  getLabel,
  getOptions,
  handleChange,
  handleRemoveArtist,
  onSearchTypeChange,
  getRedirection,
  getTermOfServiceLink,
  getPrivacyLink,
} from "./helper";
import { forbiddenCheck, debounce } from "../../helper";
import ErrorForbidden from "../Misc/ErrorPage/ErrorForbidden";
import {
  FORM_VIEW,
  FORBIDDEN,
  NEW_USER_REGISTERED,
  ARTIST_ALREADY_LINKED,
  ERRORED,
  PENDING,
  MAINTENANCE,
  LOGIN_SCREEN,
  USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED,
  USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED,
  USER_REGISTERED_NEW_ARTIST_ADDED,
  MAX_ARTIST_LINKED,
  MAX_ARTIST_LINKED_NOT_REGISTERED,
  EMAIL_INVALID_ERROR,
  PARTNERS_MAP,
} from "./constants";
import AcknowledgementScreen from "../AcknowledgementScreen/AcknowledgementScreen";
import SiteMaintenancePage from "../Misc/ErrorPage/SiteMaintenancePage";
import LoginScreen from "../LoginScreen";
import { getPartnerName } from "../Getfunded/helper";
import segment from "../../../../utils/segment";

class GetFunded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      simpleLoader: false,
      identifier: "email",
      selectOptions: [],
      id: "",
      name: "",
      image: "",
      messageSent: true,
      captcha: "",
      index: "",
      searchType: "name",
      artistId: "",
      step: FORM_VIEW,
      artistRange: "",
      landingPageUrl: "",
      loginUrl: "",
      errorMsg: "",
      email: "",
      userId: "",
      verificationCode: "",
      whitelabelName: "",
      isGeneric: false,
      affiliateId: null,
      isChecked: false,
    };
    this.initialState = this.state;
    this.getOptions = debounce(getOptions.bind(this), 600);
    this.textChanges = debounce(this.textChange, 1);
    this.textChangeUnitTest = this.textChange.bind(this);
    this.unitTest = getOptions.bind(this);
    this.captchaRef = React.createRef(null);
  }

  onChange = (token, values, setFieldError) => {
    this.setState({ captcha: token });
    this.handelSubmit(token, values, setFieldError);
  };

  componentDidMount() {
    forbiddenCheck().catch(
      (err) =>
        err?.response?.status === 403 && this.setState({ step: FORBIDDEN })
    );
    this.getInstance();
    if (this.checkGenericUrl() && window.top.location.pathname.split("/")[1]) {
      this.setState(
        {
          isGeneric: true,
          affiliateId: window.top.location.pathname.split("/")[1],
        },
        this.getValidate
      );
    }
  }

  getInstance = () => {
    const data = {
      method: "GET",
    };
    this.setState({ simpleLoader: true });
    const requestURL = `${API_URL}${ADMIN}${WHITELABEL_INSTANCE}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ simpleLoader: false });
        if (res.status) {
          document.documentElement.style.setProperty(
            "--cta-color",
            res?.data?.appStyle?.ctaColor || ""
          );
          document.documentElement.style.setProperty(
            "--primary-color",
            res?.data?.primaryColor || ""
          );
          this.setState(
            {
              loginUrl: res?.data?.frontendDomain || "",
              whitelabelName: res?.data?.displayName || "",
              slugName: res?.data?.slugName,
            },
            () => {
              this.initialState = this.state;
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ simpleLoader: false });
      });
  };

  checkGenericUrl = () => {
    return window.top.location.origin ===
      process.env.REACT_APP_GENERIC_CHORDCASH_URL
      ? true
      : false;
  };

  getValidate = () => {
    const payload = {
      affiliateId: this.state.affiliateId,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    this.setState({ simpleLoader: true });
    const requestURL = `${API_URL}${AUTH}${VALIDATE_PARTNER}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ simpleLoader: false });
        if (res.status) {
          if (!res.data) {
            getRedirection(
              `${process.env.REACT_APP_GENERIC_CHORDCASH_URL}/error-404`
            );
          }
          return;
        }
        getRedirection(
          `${process.env.REACT_APP_GENERIC_CHORDCASH_URL}/error-404`
        );
      })
      .catch((err) => {
        this.setState({ simpleLoader: false });
        getRedirection(
          `${process.env.REACT_APP_GENERIC_CHORDCASH_URL}/error-404`
        );
      });
  };

  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    phone: Yup.string().label("Phone number"),
  });

  agentDomain = window.top.location.hostname.split(".")[1];
  agentTrack =
    process.env.REACT_APP_AGENT_SUBDOMAIN === this.agentDomain &&
    window.top.location.pathname.includes("offers");
  agentCode = window.top.location.href.split("/");

  handelSubmit = (token, values, setFieldError) => {
    this.setState({ loading: true });
    const identifier = values.email;
    const payload = {
      spotifyId: this.state.id,
      emailOrPhone: identifier,
      recaptchaToken: token,
    };
    this.agentTrack && Object.assign(payload, { agentCode: this.agentCode[4] });
    this.state.isGeneric &&
      Object.assign(payload, {
        affiliateId: this.state.affiliateId,
      });
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNUP_API}`;
    request(requestURL, data)
      .then(async (res) => {
        this.setState({ loading: false });
        if (!res.status) {
          if (res.message === EMAIL_INVALID_ERROR) {
            setFieldError("email", res.message);
          } else {
            this.setState({ step: ERRORED, errorMsg: res.message });
          }
        } else if (
          [
            NEW_USER_REGISTERED,
            USER_REGISTERED_NEW_ARTIST_ADDED,
            ARTIST_ALREADY_LINKED,
          ].indexOf(res.data.signup_status) !== -1
        ) {
          this.setState({
            step: res.data.signup_status,
            artistRange: res.data.artist_range,
            landingPageUrl: res.data.landingPageUrl,
            userId: res.data.userId,
            verificationCode: res.data.verificationCode,
            artistId: res.data.artist_id,
          });
          segment.identify.update(res.data.userId, {
            source: 'widget',
            component: 'GetFunded',
            callId: '1',
          });
        } else if (res.data.signup_status === PENDING) {
          this.setState({
            step: PENDING,
            image: res?.data?.profile_picture?.[2]?.url,
            name: res.data.artist_name,
            landingPageUrl: res.data.landingPageUrl,
          });
          segment.identify.update(res.data.userId, {
            source: 'widget',
            component: 'GetFunded',
            callId: '2',
          });
        } else if (
          [
            USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED,
            USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED,
          ].indexOf(res.data.signup_status) !== -1
        ) {
          this.setState({
            step: res.data.signup_status,
            landingPageUrl: res.data.landingPageUrl,
          });
          segment.identify.update(res.data.userId, {
            source: 'widget',
            component: 'GetFunded',
            callId: '3',
          });
        } else if (res.data.signup_status === MAX_ARTIST_LINKED) {
          const step = !res.data.verificationCode
            ? MAX_ARTIST_LINKED
            : MAX_ARTIST_LINKED_NOT_REGISTERED;
          this.setState({
            landingPageUrl: res.data.landingPageUrl,
            artistRange: res.data.artist_range,
            userId: res.data.userId,
            verificationCode: res.data.verificationCode,
            artistId: res.data.artist_id,
            image: res?.data?.profile_picture?.[2]?.url,
            name: res.data.artist_name,
            step,
          });
          segment.identify.update(res.data.userId, {
            source: 'widget',
            component: 'GetFunded',
            callId: '4',
          });
        } else {
          this.setState({ step: ERRORED });
        }
        return [];
      })
      .catch((err) => {
        if (err?.response?.status === 503) {
          err.response.json().then((res) => {
            this.setState({
              step: MAINTENANCE,
              loading: false,
              name: res?.data?.widgetHeaderText,
              errorMsg: res?.data?.widgetDetailText,
            });
          });
          return false;
        }
        this.setState({
          loading: false,
          step: ERRORED,
        });
      });
  };
  handelCaptchaSubmit = () => {
    if (this.state.step === FORM_VIEW) {
      segment.track.usedWidget(this.state);
    }
    this.captchaRef.current.execute();
  };
  textChange = (value, otherValue) => {
    if (!value && otherValue?.action !== "menu-close") {
      document.getElementsByClassName("select__placeholder")[0]?.classList &&
        document
          .getElementsByClassName("select__placeholder")[0]
          .classList.toggle("bb_active");
      return true;
    }
  };

  focusChange = (e) => {
    if (e.type === "blur" && !document.getElementById("single_value")) {
      document.getElementsByClassName("select__placeholder")[0]?.classList &&
        document
          .getElementsByClassName("select__placeholder")[0]
          .classList.remove("bb_active");
      const searchDiv = document.getElementsByClassName(
        styles.spotifySearch
      )[0];
      searchDiv
        .getElementsByClassName("floating")[0]
        .classList.remove("bb_active");
      this.state.selectOptions.length > 0 &&
        searchDiv
          .getElementsByClassName("floating")[0]
          .classList.add("bb_active");
      return true;
    }
    if (e.type === "focus") {
      document.getElementsByClassName("select__placeholder")[0]?.classList &&
        document
          .getElementsByClassName("select__placeholder")[0]
          .classList.add("bb_active");
      const searchDiv = document.getElementsByClassName(
        styles.spotifySearch
      )[0];
      searchDiv
        .getElementsByClassName("floating")[0]
        .classList.add("bb_active");
    }
  };

  onEmailChange = (e) => {
    this.setState({ email: e });
  };

  onCheckChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  getFundedForm = () => (
    <div className="theme-bg-dark">
      <div className={authStyles.loginContent}>
        <Formik
          initialValues={{
            identifier: "",
            email: "",
            isChecked: "",
          }}
          validationSchema={this.validationSchemaEmail}
          onSubmit={this.handelCaptchaSubmit}
          data-testid="formRender"
        >
          {({ values, setFieldError }) => (
            <div className={authStyles.formContainer}>
              {HEADER_TEXT && (
                <h2 className={`getFundedHeaderText`}>{HEADER_TEXT}</h2>
              )}
              <Form className="m-0">
                <div className={`form-group ${styles.padding}`}>
                  <div className={styles.spotifySearch}>
                    <label className="floating" htmlFor="identifier">
                      {getLabel(this.state.searchType)}
                    </label>
                    <Select
                      maxMenuHeight={176}
                      id="artistSelect"
                      data-test="SpotifySelect"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isClearable={false}
                      loadOptions={(inputValue, callback) =>
                        this.getOptions.call(this, inputValue, callback)
                      }
                      onChange={(e) => handleChange.call(this, e)}
                      onInputChange={this.textChanges}
                      onBlur={this.focusChange}
                      onFocus={this.focusChange}
                      formatOptionLabel={formatOptionLabel}
                      name="identifier"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        SingleValue: (prop) =>
                          SingleValue(
                            prop,
                            (props) => handleRemoveArtist.call(this, props),
                            this.state.image
                          ),
                        NoOptionsMessage,
                        LoadingMessage,
                      }}
                      styles={autofillStyle}
                      placeholder={
                        this.state.searchType === "name"
                          ? "Artist name"
                          : "Spotify ID"
                      }
                    />
                  </div>
                  <span className={styles.emailorphone}>
                    <span
                      data-test="SearchType"
                      onClick={() => onSearchTypeChange.call(this)}
                    >
                      {this.state.searchType === "name"
                        ? "Use Spotify ID instead"
                        : "I prefer artist name"}
                    </span>
                  </span>
                </div>
                <div
                  className={`form-group ${styles.padding} ${styles.formMargin0} mb-3`}
                >
                  <FormField
                    name="email"
                    type="text"
                    placeholder="Email address"
                    label="Email"
                    onChangeHandle={this.onEmailChange}
                  />
                </div>

                <div className={`form-group mb-0 ${styles.padding}`}>
                  <div className={styles.policyDiv}>
                    <div
                      className={`${styles.checkDiv} ${
                        !PARTNERS_MAP[this.state.slugName] &&
                        styles.checkboxAlign
                      }`}
                    >
                      <FormField
                        name="checkAgreement"
                        type="checkbox"
                        as="checkbox"
                        onChangeHandle={this.onCheckChange}
                      />
                    </div>
                    <label htmlFor="checkAgreement">
                      {PARTNERS_MAP[this.state.slugName] ? (
                        <p className={styles.policy}>
                          I agree to {getPartnerName(this.state.loginUrl)}’s{" "}
                          <a
                            href={getTermOfServiceLink(this.state.slugName)}
                            target="blank"
                          >
                            Terms of Service.
                          </a>{" "}
                          I acknowledge {getPartnerName(this.state.loginUrl)}
                          ’s{" "}
                          <a
                            href={getPrivacyLink(this.state.slugName)}
                            target="blank"
                          >
                            Privacy Notice
                          </a>{" "}
                          and agree that my information will be shared with{" "}
                          {PARTNERS_MAP[this.state.slugName]}.
                        </p>
                      ) : (
                        <p className={styles.policy}>
                          I agree to {getPartnerName(this.state.loginUrl)}’s{" "}
                          <a
                            href={getTermOfServiceLink(this.state.slugName)}
                            target="blank"
                          >
                            Terms of Service
                          </a>{" "}
                          and acknowledge {getPartnerName(this.state.loginUrl)}
                          ’s{" "}
                          <a
                            href={getPrivacyLink(this.state.slugName)}
                            target="blank"
                          >
                            Privacy Notice
                          </a>
                        </p>
                      )}
                    </label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Recaptcha
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                      theme="light"
                      sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                      size="invisible"
                      ref={this.captchaRef}
                      onChange={(e) => this.onChange(e, values, setFieldError)}
                      className={styles.captchaDiv}
                      data-testid="test-captcha"
                    />
                  </div>
                  <button
                    className={authStyles.btnPrimary}
                    disabled={
                      this.state.loading ||
                      !this.state.id ||
                      !this.state.email ||
                      !this.state.isChecked
                    }
                    type="submit"
                    name="submit"
                  >
                    Let's go!
                  </button>

                  {this.state.loginUrl && (
                    <div className={styles.loginLink}>
                      <span>I already have an account,</span>{" "}
                      <span
                        href={`${this.state.loginUrl}${LOGIN}`}
                        className={authStyles.linkText}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({ step: LOGIN_SCREEN })}
                      >
                        Log In
                      </span>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      {this.state.loading && <Loader />}
      {this.state.simpleLoader && <Loader noText />}
    </div>
  );

  onBack = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    switch (this.state.step) {
      case FORM_VIEW:
        return this.getFundedForm();
      case FORBIDDEN:
        return <ErrorForbidden />;
      case MAINTENANCE:
        return (
          <SiteMaintenancePage
            headerText={this.state.name}
            detailText={this.state.errorMsg}
          />
        );
      case PENDING:
      case ERRORED:
        return (
          <AcknowledgementScreen
            name={this.state.name}
            image={this.state.image}
            registered={this.state.step}
            artistId={this.state.artistId}
            onBack={this.onBack}
            landingPageUrl={this.state.landingPageUrl}
            errorMsg={this.state.errorMsg}
          />
        );
      case USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED:
      case USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED:
      case MAX_ARTIST_LINKED:
      case LOGIN_SCREEN:
        return (
          <LoginScreen
            onBack={this.onBack}
            landingPageUrl={this.state.loginUrl}
            whitelabelName={this.state.whitelabelName}
          />
        );
      case NEW_USER_REGISTERED:
      case USER_REGISTERED_NEW_ARTIST_ADDED:
      case ARTIST_ALREADY_LINKED:
      case MAX_ARTIST_LINKED_NOT_REGISTERED:
        window.top.location.href = `${this.state.landingPageUrl}${ACK_REDIRECT}?name=${this.state.name}&profile=${this.state.image}&status=${this.state.artistRange}&artistId=${this.state.artistId}&_id=${this.state.userId}&code=${this.state.verificationCode}`;
        return null;
      default:
        return null;
    }
  }
}

export default GetFunded;
