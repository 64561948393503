import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import styles from "./FormField.module.scss";
import { VISIBILITY, VISIBILITY_OFF } from "../MaterialIcons/constants";
import SvgIcons from "../MaterialIcons/SvgIcons";

class FormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconPass: VISIBILITY,
      passType: "password",
      country: "",
      labelActive: false,
    };
    this.inputRef = createRef();
  }

  changeInputType = () => {
    this.state.passType === "password"
      ? this.setState({ passType: "text", iconPass: VISIBILITY_OFF })
      : this.setState({ passType: "password", iconPass: VISIBILITY });
  };

  renderLabelUI = (arrayLabel) => {
    const { label, as, icon, name } = this.props;
    return (
      <label
        className={`floating ${icon ? "icon" : ""} ${
          this.state.labelActive || as === "phone" ? "bb_active" : ""
        }`}
        htmlFor={name}
      >
        {label || arrayLabel}
      </label>
    );
  };

  focusChange = (e) => {
    if (e?.type === "focus") {
      this.setState({ labelActive: true });
    } else if (e?.target?.value) {
      this.setState({ labelActive: true });
    } else {
      this.setState({ labelActive: false });
    }
  };

  getFieldComponent = () => {
    const {
      type,
      name,
      placeholder,
      className,
      style,
      as = "input",
      onChangeHandle,
      ...otherProps
    } = this.props;
    switch (as) {
      case "input":
        return (
          <Field type={type} name={name} as={as} {...otherProps}>
            {({ field }) => (
              <input
                placeholder={placeholder}
                className={className}
                style={style}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChangeHandle && onChangeHandle(e.target.value);
                }}
              />
            )}
          </Field>
        );
      case "password":
        return (
          <Field name={name} {...otherProps}>
            {({ field }) => (
              <div className={styles.container}>
                <input
                  type={this.state.passType}
                  className={className}
                  style={style}
                  placeholder={placeholder}
                  onFocus={this.focusChange}
                  {...field}
                  onBlur={(e) => {
                    field.onBlur(e);
                    this.focusChange(e);
                  }}
                />
                <i onClick={this.changeInputType}>
                  <SvgIcons
                    icon={this.state.iconPass}
                    className={`${styles.icon}`}
                  />
                </i>
              </div>
            )}
          </Field>
        );
      case "checkbox":
        return (
          <Field
            type={type}
            name={name}
            className={className}
            placeholder={placeholder}
            style={style}
            as={as}
            {...otherProps}
          >
            {({ field }) => (
              <input
                type={as}
                className={`${className} "mr-3"`}
                name={name}
                id={`${name}`}
                onChange={(e) => {
                  field.onChange(e);
                  onChangeHandle && onChangeHandle(e.target.value);
                }}
              />
            )}
          </Field>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="mb-4">
        {this.getFieldComponent()}
        {this.renderLabelUI()}
        <ErrorMessage
          name={this.props.name}
          component="div"
          className="invalid-feedback d-flex position-absolute w-auto"
          style={{ marginRight: 40, lineHeight: "15px" }}
        />
      </div>
    );
  }
}

FormField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  as: PropTypes.string,
};

export default FormField;
