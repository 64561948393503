import React from "react";
import styles from "./Loader.module.scss";

const Loader = (props) => {
  return (
    <div className={`${styles.container} ${styles.light}`}>
      <div className={styles.loginContent}>
        <div className={`${styles.detailContainer}`}>
          {!props.noText && <h1>Gathering data</h1>}
          <p>
            <i className={`spinner-border`}></i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Loader;
