import React from "react";
import styles from "../../AcknowledgementScreen/AcknowledgementScreen.module.scss";

const SiteMaintenancePage = (props) => {
  return (
    <div className={`${styles.detailContainer}`}>
      <h2>{props.headerText || "Please try again later."}</h2>
      <div className={`d-flex mt-3`}>
        <div className={styles.txtContent}>
          <p className={`mb-5`}>
            {props.detailText ||
              "We are currently going through some site maintenance. Please come back within 24 hours."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SiteMaintenancePage;
