import React, { Component } from "react";
import styles from "../Auth.module.scss";
import invoke from "lodash.invoke";
import base from "base-64";
import * as Yup from "yup";
import FormikForm from "../../../../component/Form/FormikForm";
import { Form } from "formik";
import FormField from "../../../../component/FormField/FormField";
import {
  API_URL,
  AUTH,
  SIGNIN_API,
  LOGIN,
  FORGOT_PASS,
  ACK_REDIRECT,
} from "../../constants";
import {
  PASS_NUM_SPECIAL_REGEX,
  PASS_UPPER_LOWER_REGEX,
  EMAIL_REGEX,
} from "../constants";
import request from "../../../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../helper";
import Loader from "../../../../component/Loader";
import { ReactComponent as LoginIcon } from "../../../../assets/np_login.svg";
import segment from "../../../../utils/segment";
class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      name: "",
      artistId: "",
      artistRange: "",
      landingPageUrl: "",
      userId: "",
      verificationCode: "",
      image: "",
      landingPage: "",
    };
  }
  passwordValidate = Yup.string()
    .required()
    .min(8, "Please enter valid password between 8 to 20 characters")
    .max(20, "Please enter valid password between 8 to 20 characters")
    .matches(
      PASS_UPPER_LOWER_REGEX,
      "Password should have at least 1 uppercase letter & 1 lowercase letter."
    )
    .matches(
      PASS_NUM_SPECIAL_REGEX,
      "Password should have at least 1 number & 1 special character."
    )
    .label("Password");
  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    password: this.passwordValidate,
  });
  handleRedirect = () => {
    window.top.location.href = `${this.state.landingPage}${ACK_REDIRECT}?name=${this.state.name}&profile=${this.state.image}&status=${this.state.artistRange}&artistId=${this.state.artistId}&_id=${this.state.userId}&code=${this.state.verificationCode}`;
  };

  loginUser = (data) => {
    const redirectToken = base.encode(
      `?token=${data.artistAccessToken}&timestamp=${Date.now()}`
    );
    window.top.location.replace(
      `${data.landingPageUrl}${LOGIN}/${redirectToken}`
    );
    return true;
  };
  forgetUser = () => {
    window.top.location.replace(`${this.props.landingPageUrl}${FORGOT_PASS}`);
  };
  handelSubmit = (values) => {
    this.setState({ loading: true });
    segment.track.loginWidget(values);
    const payload = {
      emailOrPhone: values.email,
      password: values.password,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNIN_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (res.data && res.data.isActive === false) {
            this.setState({
              landingPage: res.data.landingPageUrl,
              artistRange: res.data.artist_range,
              userId: res.data.userId,
              verificationCode: res.data.verificationCode,
              artistId: res.data.artist_id,
              image: res?.data?.profile_picture?.[2]?.url,
              name: res.data.artist_name,
              isOpen: true,
            });
            segment.identify.update(res.data.userId, {
              source: 'widget',
              component: 'LoginScreen',
              callId: '5',
            });
          } else {
            this.loginUser(res.data);
          }
          return true;
        }
        toast.error(res?.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };
  render() {
    return (
      <div className="theme-bg-dark">
        <div className={styles.loginContent}>
          {!this.state.isOpen ? (
            <FormikForm
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={this.validationSchemaEmail}
              onSubmit={this.handelSubmit}
            >
              <div className={styles.formContainer}>
                <Form>
                  <LoginIcon className={styles.loginIcon} />
                  <h2 className={`getFundedHeaderText`}>Welcome Back!</h2>
                  <p className={styles.subTitle}>
                    Login to your {this.props.whitelabelName} account with your
                    email and password
                  </p>
                  <div className={`form-group mb-0 ${styles.loginInputs}`}>
                    <FormField
                      name="email"
                      placeholder="Email address"
                      type="text"
                      label="Email address"
                    />
                  </div>
                  <div
                    className={`form-group ${styles.loginInputs} ${styles.passwordInput}`}
                  >
                    <FormField
                      name="password"
                      placeholder="Password"
                      as="password"
                      label="Password"
                    />
                    <span
                      className={styles.forgotPassword}
                      onClick={this.forgetUser}
                      data-testid="forget-btn"
                    >
                      Forgot it?
                    </span>
                  </div>
                  <div className={`form-group ${styles.clearFloat}`}>
                    <button
                      type="submit"
                      className={styles.btnPrimary}
                      disabled={this.state.loading}
                    >
                      Let's Go
                    </button>
                    <p className={styles.loginLink}>
                      I don't have an account yet,{" "}
                      <span
                        className={styles.linkText}
                        onClick={this.props.onBack}
                      >
                        Sign Up
                      </span>
                    </p>
                  </div>
                </Form>
              </div>
            </FormikForm>
          ) : (
            <div className={styles.errorContainer}>
              <div>
                <h3>Oops! Your account setup is incomplete.</h3>
                <p>Click below to enter your details and create a password </p>
              </div>
              <div className={styles.btnContainer}>
                <button data-testid="redirectBtn" onClick={this.handleRedirect}>
                  LET’S GO
                </button>
              </div>
            </div>
          )}
        </div>
        {this.state.loading && <Loader noText />}
      </div>
    );
  }
}
export default LoginScreen;
