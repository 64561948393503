import { datadogRum } from "@datadog/browser-rum";

if (process.env.REACT_APP_DATA_DOG_CALL === "true") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATA_DOG_SITE,
    service: process.env.REACT_APP_DATA_DOG_SERVICE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}
