/* Constants for API */
export const API_URL =
  window.GET_FUNDED_API_URL || process.env.REACT_APP_API_URL;
export const AUTH = "/auth";
export const SIGNUP_API = "/signup";
export const SIGNIN_API = "/signin";
export const REGISTER_API = "/register";
export const USER_API = "/user";
export const SPOTIFY_USER_API = "/spotify-users";
export const VERIFICATION_RESEND = "/verification/resend";
export const ADMIN = "/admin";
export const WHITELABEL_INSTANCE = "/whitelabel/instance";
export const VALIDATE_PARTNER = "/validate-partner";

/* Constants for Routes */
export const ACK_REDIRECT = "/redirect";
export const LOGIN = "/login";
export const FORGOT_PASS = "/forgot-password";

/* Contact mail */

export const FAILED_TO_FETCH =
  "We're experiencing higher than normal traffic, which is causing an issue. Please try again in a moment.";

export const devURL = "https://dev.beatbread.com";
export const demoURL = "https://demo.beatbread.com";
export const appURL = "https://app.beatbread.com";

export const HEADER_TEXT = window.WIDGET_HEADLINE;
