import React from "react";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import styles from "./Getfunded.module.scss";
import request from "../../../../utils/request";
import { API_URL, USER_API, SPOTIFY_USER_API } from "../../constants";
import {
  ERRORED,
  BB_SUBDOMAIN,
  BEATBREAD_PRIVACY_POLICY,
  CHORDCASH_PRIVACY_POLICY,
  CHORDCASH_TERMS_OF_SERVICE,
  BEATBREAD_TERMS_OF_SERVICE,
} from "./constants";

export const getSpotifyData = (data) => data.items || (data.id ? [data] : []);
export const NoOptionsMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={styles.noOption}>No data to show</div>
    )
  );
};
export function onSearchTypeChange() {
  this.state.searchType === "name"
    ? this.setState({ searchType: "id" })
    : this.setState({ searchType: "name" });
}
export function handleRemoveArtist(clearValue) {
  this.setState({ id: "", name: "", image: "", selectOptions: [] });
  document.querySelector("#artistSelect .select__input").style.display =
    "inline-block";

  document.getElementById("single_value").style.display = "none";
  clearValue();
  setTimeout(() => {
    const searchDiv = document.getElementsByClassName(styles.spotifySearch)[0];
    searchDiv &&
      searchDiv
        .getElementsByClassName("floating")[0]
        .classList.remove("bb_active");
    document.getElementsByClassName("select__placeholder")[0]?.classList &&
      document
        .getElementsByClassName("select__placeholder")[0]
        .classList.remove("bb_active");
  }, 0);
}

export function handleRemoveSearchArtist(clearValue) {
  this.setState({ id: "", name: "", image: "", selectOptions: [] });
  document.getElementsByClassName("select__input")[0].style.display =
    "inline-block";
  document.getElementById("single_value").style.display = "none";
  clearValue();
}

function getOptionResponse(res, thisref) {
  const data = getSpotifyData(res.data);
  const options = [];
  data.map((item) =>
    options.push({
      value: item.id,
      label: item.name,
      imageUrl: item?.images?.[2]?.url,
    })
  );
  thisref.setState({ selectOptions: options });
  return options;
}

export function handleChange(e) {
  if (e) {
    document.getElementsByClassName("select__input")[0].style.display = "none";

    this.setState({ id: e.value, name: e.label, image: e.imageUrl });
  }
  if (e !== null) {
    const searchDiv = document.getElementsByClassName(styles.spotifySearch)[0];
    searchDiv &&
      searchDiv
        .getElementsByClassName("floating")[0]
        .classList.add("bb_active");
  }
}

export function getOptions(inputValue, callback) {
  if (inputValue.length < 1) {
    callback([]);
  } else {
    const reqData = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${SPOTIFY_USER_API}?q=${inputValue}&type=${this.state.searchType}&limit=40`;

    request(requestURL, reqData)
      .then((res) => {
        if (!res.status) {
          this.setState({ step: ERRORED });
          callback([]);
        } else {
          const thisref = this;
          callback(getOptionResponse(res, thisref));
        }
      })
      .catch((err) => {
        callback([]);
        this.setState({ step: ERRORED });
      });
  }
}

export const LoadingMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={styles.noOption}>
        <span className="spinner-border spinner-border-sm"></span>
      </div>
    )
  );
};

export const SingleValue = (
  { data, selectProps, ...others },
  handleRemove,
  imgURL
) => {
  return (
    <div id="single_value" className={styles.selectOption}>
      <div className={`d-flex align-items-center`}>
        <div className={styles.profile}>
          {imgURL ? (
            <img src={imgURL} alt="artist_profile" />
          ) : (
            <SvgIcons icon={PROFILE_ICON} />
          )}
        </div>
        <div>
          <p>{selectProps.getOptionLabel(data)} </p>
        </div>
      </div>
      <button
        type="button"
        onClick={() => handleRemove(others.clearValue)}
        onTouchEnd={() => handleRemove(others.clearValue)}
        data-testid="removeBtn"
      >
        Remove
      </button>
    </div>
  );
};

export const getLabel = (val) =>
  val === "name" ? "Artist Name" : "Spotify ID";

export const getSubDomain = (url) => {
  if (url.includes("//")) {
    return url.split("/")[2];
  }
  return BB_SUBDOMAIN[0];
};

export const getPartnerName = (url) =>
  BB_SUBDOMAIN.indexOf(getSubDomain(url)) !== -1 ? "beatBread" : "chordCash";

export const getRedirection = (url) => window.top.location.replace(url);

export const getPrivacyLink = (slugName) =>
  slugName === "app" ? BEATBREAD_PRIVACY_POLICY : CHORDCASH_PRIVACY_POLICY;

export const getTermOfServiceLink = (slugName) =>
  slugName === "app" ? BEATBREAD_TERMS_OF_SERVICE : CHORDCASH_TERMS_OF_SERVICE;
