import styles from '../../../../styles/global.scss';

const autofillStyle = {
  singleValue: (provided) => ({
    ...provided,
    alignItems: "center",
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    outline: "transparent",
    border: "none",
    backgroundColor: "#fff",
    borderRadius: 2,
    borderBottom: "1px solid #bbbbbb",
    padding: 12,
    "&:hover": { borderColor: "none", outline: "none" },
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
    color: "#000",
    cursor: "text",
  }),

  input: (base) => ({
    ...base,
    color: styles.primaryColor,
    margin: 0,
    padding: 0,
  }),

  menu: (base, state) => ({
    ...base,
    borderColor: state.selectProps.inputValue.length
      ? "#bbbbbb"
      : "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    outline: "none",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    paddingBottom: state.selectProps.inputValue.length ? "10px" : "0",
    backgroundColor: state.selectProps.inputValue.length
      ? "#fff"
      : "transparent",
    width: state.selectProps.inputValue.length ? "100%" : "0",
  }),

  option: (base) => ({
    ...base,
    backgroundColor: "#fff",
    padding: "7px 20px",
    "&:active": { backgroundColor: "transparent" },
  }),

  menuList: (base) => ({
    ...base,
    paddingTop: 10,
    marginTop: "-1px",
    backgroundColor: "#fff",
  }),
};

export default autofillStyle;
