import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "../component/ThirdPartyIntegration/DataDog";
import "react-toastify/dist/ReactToastify.css";
import "../styles/global.scss";
import GetFunded from "./pages/Auth/Getfunded";
import { importSegmentSnippet } from "../utils/importedScripts";

importSegmentSnippet();

export default class MainRoutes extends React.Component {
  render() {
    return (
      <>
        <ToastContainer
          className="Toast"
          type="default"
          position={toast.POSITION.TOP_RIGHT}
          autoClose={4000}
          hideProgressBar
          pauseOnHover
        />
        <GetFunded />
      </>
    );
  }
}
