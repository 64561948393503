import React from "react";
import styles from "../Misc.module.scss";

const ErrorForbidden = () => {
  return (
    <div className="theme-bg-dark">
      <div className={styles.errorContainer}>
        <div>
          <h1>403</h1>
        </div>
        <div>
          <h4>Forbidden</h4>
        </div>
        <a href="mailto:help@beatbread.com" className={styles.homeLink}>
          Contact us
        </a>
      </div>
    </div>
  );
};

export default ErrorForbidden;
