import React from "react";
import { VISIBILITY, VISIBILITY_OFF, PROFILE_ICON } from "./constants";
import { ReactComponent as AccountCircle } from "../../assets/account_circle.svg";
import { ReactComponent as VisibilityOff } from "../../assets/visibility_off.svg";
import { ReactComponent as Visibility } from "../../assets/visibility.svg";

const SvgIcons = ({ icon, className, ...otherProps }) => {
  const getIconComponent = (Component) => (
    <Component className={className} {...otherProps} />
  );

  switch (icon) {
    case VISIBILITY:
      return getIconComponent(Visibility);
    case VISIBILITY_OFF:
      return getIconComponent(VisibilityOff);
    case PROFILE_ICON:
      return getIconComponent(AccountCircle);
    default:
      return null;
  }
};

export default SvgIcons;
