import { FAILED_TO_FETCH, API_URL } from "./constants";
import request from "../../utils/request";

export const getErrorMessage = (err) => {
  if (err?.message === "Failed to fetch") {
    return FAILED_TO_FETCH;
  }
  return err?.message;
};

export const forbiddenCheck = () => {
  const data = {
    method: "GET",
  };
  const requestURL = `${API_URL}`;
  return request(requestURL, data);
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
