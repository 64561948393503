export const FORM_VIEW = "formView";
export const FORBIDDEN = "forbidden";
export const ACK_PAGE = "acknowledgementPage";
export const USER_ALREADY_REGISTERED = "USER_ALREADY_REGISTERED";
export const NEW_USER_REGISTERED = "NEW_USER_REGISTERED";
export const USER_REGISTERED_NEW_ARTIST_ADDED =
  "USER_REGISTERED_NEW_ARTIST_ADDED";
export const USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED =
  "USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED";
export const USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED =
  "USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED";
export const MAX_ARTIST_LINKED = "MAX_ARTIST_LINKED";
export const MAX_ARTIST_LINKED_NOT_REGISTERED =
  "MAX_ARTIST_LINKED_NOT_REGISTERED";
export const ARTIST_ALREADY_LINKED = "ARTIST_ALREADY_LINKED";
export const ERRORED = "ERRORED";
export const PENDING = "OFFER_PENDING";
export const MAINTENANCE = "maintenance";
export const LOGIN_SCREEN = "LOGIN_SCREEN";

export const BB_CC_DOMAIN = ["beatbread.com", "chordcash.com"];
export const BB_SUBDOMAIN = [
  "app.beatbread.com",
  "dev.beatbread.com",
  "demo.beatbread.com",
];
export const PARTNERS_MAP = {
  soundexchange: "SoundExchange",
};
export const EMAIL_INVALID_ERROR =
  "Email address is invalid. Please try again.";

export const BEATBREAD_PRIVACY_POLICY =
  "https://app.termly.io/document/privacy-policy/58b9a999-6c7a-4bc8-aade-765f58591ad2";

export const BEATBREAD_TERMS_OF_SERVICE =
  "http://www.beatbread.com/terms_of_use";

export const CHORDCASH_PRIVACY_POLICY =
  "https://app.termly.io/document/privacy-policy/8bf6fecf-937d-4160-9a22-2838cee37ab4";

export const CHORDCASH_TERMS_OF_SERVICE =
  "https://www.chordcash.com/termsofuse";
