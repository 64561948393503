import { get, invoke } from "lodash";

const IS_TEST_REGEX = /(\+|beatbread|growexx|chordcash)/i;
const isTest = (inEmail) => IS_TEST_REGEX.test(inEmail);

const segment = {
  identify: {
    update: (userId,options={}) => invoke(window, 'analytics.identify', userId, {}, {
      eventName: 'update',
      source: 'widget',
      ...options,
    }),
    usedWidget: (values,options={}) => invoke(window, "analytics.identify", get(values, "userId"), {
      source: 'widget',
      email: get(values, "email"),
    },{
      eventName: 'usedWidget',
      source: 'widget',
      ...options,
    }),
    loginWidget: (values,options={}) => invoke(window, "analytics.identify", get(values, "userId"), {
      source: 'widget',
      firstName: get(values, "fname", ""),
      lastName: get(values, "lname", ""),
      role: get(values, "role", ""),
      country: get(values, "country", ""),
      phone: get(values, "phoneNumber", ""),
      email: get(values, "email"),
    },{
      eventName: 'loginWidget',
      source: 'widget',
      ...options,
    }),
  },
  track: {
    usedWidget: (values,options={}) => invoke(window, "analytics.track", "Used Widget", {
      source: "widget",
      artistName: get(values, "name"),
      agreePrivacyNotice: get(values, "isChecked"),
      isTest: isTest(get(values, "email")),
      userPath: "legacy",
      customerLifecycleStage: "subscriber",
    },{
      eventName: 'usedWidget',
      source: 'widget',
      ...options,
    }),
    loginWidget: (values,options={}) => invoke(window, "analytics.track", "Logged In", {
      source: "widget",
      emailAddress: get(values, "email"),
      isTest: isTest(get(values, "email")),
      userPath: "legacy",
      customerLifecycleStage: "qualified_lead",
      funnelStage: "signed_up",
      leadStatus: "open_multi_artist",
    },{
      eventName: 'loginWidget',
      source: 'widget',
      ...options,
    }), 
  },
};

export default segment;