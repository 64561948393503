import React, { Component } from "react";
import styles from "./AcknowledgementScreen.module.scss";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import {
  USER_ALREADY_REGISTERED,
  NEW_USER_REGISTERED,
  ARTIST_ALREADY_LINKED,
  ERRORED,
  PENDING,
} from "../Getfunded/constants";
import { getPartnerName } from "../Getfunded/helper";

class AcknowledgementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  artistProfile = () => (
    <div className={styles.artistProfile}>
      <div className={styles.artistImg}>
        {this.props.image ? (
          <img src={this.props.image} alt="profile" />
        ) : (
          <SvgIcons icon={PROFILE_ICON} />
        )}
      </div>
      <p>{this.props.name}</p>
    </div>
  );

  getSuccessScreen = () => (
    <>
      <div className={`${styles.detailContainer}`}>
        <h1>We're on it!</h1>
        <div className={`d-flex mt-3`}>
          {this.artistProfile()}
          <div className={styles.txtContent}>
            <p>
              We're currently reviewing streaming data and metrics for{" "}
              {this.props.name}.
            </p>
            <p>Look out for an email from us in the next 90 minutes or less.</p>
          </div>
        </div>
      </div>
    </>
  );

  goToLogin = () => {
    window.location = this.props.landingPageUrl;
  };

  getMemberScreen = () => (
    <>
      <div className={`${styles.detailContainer}`}>
        <h1>Please login.</h1>
        <div className={`d-flex mt-3`}>
          <div className={styles.txtContent}>
            <p className={`mb-4`}>
              This email address already exists at{" "}
              {getPartnerName(this.props.landingPageUrl)}.
              <br />
              Login below to access your current artist offer.
            </p>
            <button className={styles.primaryBtn} onClick={this.goToLogin}>
              Login
            </button>
            <button className={styles.secondaryBtn} onClick={this.props.onBack}>
              Use a different email address
            </button>
          </div>
        </div>
      </div>
    </>
  );

  getAlreadyLinkedScreen = () => (
    <>
      <div className={`${styles.detailContainer}`}>
        <h1>Heads up!</h1>
        <div className={`d-flex mt-3`}>
          {this.artistProfile()}
          <div className={styles.txtContent}>
            <p>
              This artist has already been added to an account with the email
              address provided.
            </p>
          </div>
        </div>
        <button className={styles.secondaryBtn} onClick={this.props.onBack}>
          Try a different artist
        </button>
      </div>
    </>
  );

  getErroredScreen = () => (
    <div className={`${styles.detailContainer}`}>
      {this.props.errorMsg ? (
        <h1>{this.props.errorMsg}</h1>
      ) : (
        <h1>We're experiencing higher than normal traffic volume.</h1>
      )}
      <div className={`d-flex mt-3`}>
        <div className={styles.txtContent}>
          {!this.props.errorMsg && (
            <p className={`mb-5`}>
              This is a temporary issue and should resolve itself soon. Please
              try back in a few minutes.
            </p>
          )}
          <button className={styles.secondaryBtn} onClick={this.props.onBack}>
            Try again
          </button>
        </div>
      </div>
    </div>
  );

  getPendingScreen = () => (
    <>
      <div className={`${styles.detailContainer}`}>
        <h1>Your offer is still pending.</h1>
        <div className={`d-flex mt-3`}>
          {this.artistProfile()}
          <div className={styles.txtContent}>
            <p>
              We are still reviewing streaming data and metrics for{" "}
              {this.props.name}.
            </p>
            <p>Look out for an email from us in the next 90 minutes or less.</p>
          </div>
        </div>
      </div>
    </>
  );

  getScreens = () => {
    switch (this.props.registered) {
      case NEW_USER_REGISTERED:
        return this.getSuccessScreen();
      case USER_ALREADY_REGISTERED:
        return this.getMemberScreen();
      case ARTIST_ALREADY_LINKED:
        return this.getAlreadyLinkedScreen();
      case PENDING:
        return this.getPendingScreen();
      case ERRORED:
        return this.getErroredScreen();
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="theme-bg-dark">
        <div className={styles.loginContent}>{this.getScreens()}</div>
      </div>
    );
  }
}

export default AcknowledgementScreen;
